<template>
  <VList
    :hide-header="true"
    :rows="displayedRows"
    :headers="headers"
    display-pagination
    :is-loading="isLoading"
    :pagination="pagination"
    @click:row="onClickOverview"
    @update:current_page="onUpdateCurrentPage"
    @update:per_page="onUpdatePerPage"
  >
    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage :name="item.displayed_name" :src="item.displayed_avatar" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.name="{ item }">
      <div>
        <div class="font-medium whitespace-nowrap">
          {{ item.displayed_name }}
        </div>

        <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
          {{ item.displayed_details }}
        </div>
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRead from "@/composables/useRead";
import useRole from "@/composables/useRole";
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
// Components
import VList from "@/components/tables/VList";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";

export default {
  components: {
    VList,
    VImage,
    VChip
  },
  setup() {
    // MISC
    const { t } = useI18n();

    // CONSTANTS
    const documentTitle = `${t("app.instructors", 2)} - ${t("app.teaching")}`;
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.name"),
        value: "name"
      },
      {
        text: t("app.username"),
        value: "username"
      },
      {
        text: t("app.status"),
        value: "status",
        class: "w-40"
      }
    ];

    // COMPUTED
    const displayedRows = computed(() => {
      return rows.value.map(row => ({
        ...row,
        rowClass: "cursor-pointer",
        displayed_name: `${row?.firstname} ${row?.lastname}`,
        displayed_avatar: getAvatarURL(row?.avatar?.view_path),
        displayed_details: trimHTML(row?.details),
        displayed_status: row.status ? t(`app.${row?.status}`) : ""
      }));
    });

    // CUSTOM COMPOSABLES
    const { trimHTML, getAvatarURL } = useDisplay();
    const { getStatusColor } = useColor();
    const { types, searchFields } = useRole();
    const {
      pagination,
      rows,
      isLoading,
      onClickUpdate,
      onUpdateCurrentPage,
      onUpdatePerPage,
      onClickDelete,
      getData
    } = useRead({
      endpoint: "teaching.instructors",
      route: "teaching-instructors",
      relations: [],
      types,
      searchFields
    });

    // METHODS
    const onClickOverview = item => {
      onClickUpdate(item.id);
    };

    return {
      documentTitle,
      displayedRows,
      headers,
      // useColor
      getStatusColor,
      // useRead
      pagination,
      onUpdateCurrentPage,
      onUpdatePerPage,
      onClickDelete,
      rows,
      isLoading,
      onClickOverview,
      getData
    };
  }
};
</script>
